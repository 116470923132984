<template>
    <!-- <div class="slider_close" @click="hideMask"><img src="@/assets/imgs/shut.png" alt=""></div>
    <div class="title">新建 <img src="@/assets/imgs/close.png" alt="" @click="hideMask"></div> -->
    <div class="content">
        <div>
            <h4 class="global_head_title">添加/编辑任务</h4>
            <div class="content_row flex">
                <label class="info" for="">任务类型 <span class="redS">*</span></label>
                <div class="content_right taskType">
                    <!-- <a-button @click="getType(1)"><slot><img src="@/assets/imgs/task1.png" alt=""></slot>打电话</a-button>
                    <a-button @click="getType(2)"><slot><img src="@/assets/imgs/task2.png" alt=""></slot>发短信</a-button>
                    <a-button @click="getType(3)"><slot><img src="@/assets/imgs/task3.png" alt=""></slot>发微信</a-button>
                    <a-button @click="getType(4)"><slot><img src="@/assets/imgs/task4.png" alt=""></slot>发邮件</a-button>
                    <a-button @click="getType(5)"><slot><img src="@/assets/imgs/task5.png" alt=""></slot>分享文章</a-button>
                    <a-button @click="getType(6)"><slot><img src="@/assets/imgs/task5.png" alt=""></slot>分享表单</a-button>
                    <a-button @click="getType(7)"><slot><img src="@/assets/imgs/task5.png" alt=""></slot>分享文件</a-button>
                    <a-button @click="getType(8)"><slot><img src="@/assets/imgs/task5.png" alt=""></slot>分享商品</a-button>
                    <a-button @click="getType(9)"><slot><img src="@/assets/imgs/task5.png" alt=""></slot>客户拜访</a-button>
                    <a-button @click="getType(10)"><slot><img src="@/assets/imgs/task5.png" alt=""></slot>其他</a-button> -->

                    <a-radio-group v-model="type">
                        <a-radio-button value="1">打电话</a-radio-button>
                        <a-radio-button value="2">发短信</a-radio-button>
                        <a-radio-button value="3">发微信</a-radio-button>
                        <a-radio-button value="4">发邮件</a-radio-button>
                        <a-radio-button value="5">分享文章</a-radio-button>
                        <a-radio-button value="6">分享表单</a-radio-button>
                        <a-radio-button value="7">分享文件</a-radio-button>
                        <a-radio-button value="8">分享商品</a-radio-button>
                        <a-radio-button value="9">客户拜访</a-radio-button>
                        <a-radio-button value="10">其他</a-radio-button>
                    </a-radio-group>
                </div>
            </div>
            <div class="content_row flex" style="align-items: normal;">
                <label class="info" for="">任务描述 <span class="redS">*</span></label>
                <div class="content_right">
                    <a-textarea v-model="desc" placeholder="请输入任务描述" :auto-size="{ minRows: 5, maxRows: 8 }"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">任务开始时间<span class="redS">*</span></label>
                <div class="content_right">
                    <a-date-picker v-model="start" :inputReadOnly="true" size="large" />
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">任务结束时间 <span class="redS">*</span></label>
                <div class="content_right">
                    <a-date-picker v-model="end" :inputReadOnly="true" size="large" />
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">选择客户 <span class="redS">*</span></label>
                <div class="content_right">
                    <a-select mode="multiple" size="large" ref="sel" v-model="client2" placeholder="请选择要添加的客户" :open="false" @focus="chooseClient">
                        <!-- <a-select-option v-for="(item,index) in clientList" :key="index" :value="item.tmjcpb_corp__ids">
                            {{ item.tmjcpb_corp__name }}
                        </a-select-option> -->
                    </a-select>
                </div>
            </div>
            <client :clientFlag="clientFlag" :clientMode="clientMode" @updateClientFlag="updateClientFlag"></client>
            <!-- <div class="content_row flex">
                <label class="info" for="">跟进人</label>
                <div class="content_right">
                    <a-select size="large" v-model="staff_uid" placeholder="请选择跟进人">
                        <a-select-option value="default">默认</a-select-option>
                        <a-select-option v-for="(item,index) in handlerList" :key="index" :value="item.userid">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </div>
            </div> -->
            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
        </div>

        
    </div>
    
        
</template>
<script>
// import $ from "jquery";
import {requestXml,getDateTime,isMobile} from '../../../assets/js/request';
import Client from '../../../components/client.vue';
var moment = require('moment');
export default {
    components: {
		Client
	},
    props: {
        taskId: {
            type: String
        }
    },
    data() {
        return {
            clientMode: 0,
            type: 0,
            desc: "",
            // date: [],
            start: "",
            end: "",

            // clientList: [],
            client: "" || [],
            client2: "" || [],
            clientFlag: false,

            mode: 1,
            staff_uid: "default",
            // handlerList: [],
            // 任务id
            id: "",
            taskList:[],
            detail: ""
        };
    },
    mounted: function(){
        this.clientMode = 2;
        // this.getClientList();
        // this.getStaffList();
        this.id = this.$route.query.id;
        // 客户详情页添加时跳转过来的
        this.detail = this.$route.query.detail;
    
        if(this.$route.query.client){
            this.client = [Number(this.$route.query.client)];
            this.client2 = this.$route.query.clientName;
            console.log(this.client,this.client2,8888)
        }
        if(this.id){
            this.getTaskDetail();
        }
    },
    // watch:{
    //     client: function(newVal){ 
    //         console.log(newVal,79898)
    //     },
    // },
    methods: {
        // // 获取客户
        // getClientList() {
        //     requestXml("/scrm/Corp/getUnBindCorp","GET",(res) => {
        //         this.clientList = res;
        //     })
        // },
        // 获取处理人
        // getStaffList() {
        //     requestXml("/scrm/Staff/getMinList","GET",(res) => {
        //         this.handlerList = res;
        //         // console.log(this.handlerList)
        //     })
        // },
        chooseClient(){
            this.clientFlag = true;
            this.$refs.sel.blur();
        },

        updateClientFlag(data){
            if(data != 1){
                this.client = [];
                this.client2 = [];
                for(let i=0;i<data.length;i++){
                    this.client.push(data[i].tmjcpb_corp__ids);
                    this.client2.push(data[i].tmjcpb_corp__name);
                }
            }
            
            this.clientFlag = false;
        },
        // 获取任务详情
        getTaskDetail() {
            requestXml("/scrm/Task/getDetails","GET",(res) => {
                console.log(res)
                this.type = res.type +'';

                this.desc = res.task_content;
                this.start = new moment(getDateTime(res.start));
                this.end = new moment(getDateTime(res.end));
                // this.date[0] = this.start;
                // this.date[1] = this.end;
                for(let i=0;i<res.list.length;i++){
                    this.client.push(res.list[i].ids);
                    this.client2.push(res.list[i].corp_name);
                }
                console.log(this.client)
                
                this.staff_uid = res.staff_uid;
                this.staff_uid2 = res.staff_name;

                
            },{id: this.id})
        },
        // 添加任务
        submit(){
            if(!this.type){
                this.$message.warning('请选择任务类型');
                return false;
            }
            if(!this.desc){
                this.$message.warning('请填写任务描述');
                return false;
            }
            if(!this.start){
                this.$message.warning('请选择任务开始时间');
                return false;
            }
            if(!this.end){
                this.$message.warning('请选择任务结束时间');
                return false;
            }
            if(this.client.length == 0){
                this.$message.warning('请选择要添加的客户');
                return false;
            }
            // if(this.staff_uid == "default"){
            //     this.mode = 1;
            // }else{
            //     this.mode = 2;
            //     if(!this.staff_uid){
            //         this.$message.warning('请选择处理人');
            //         return false;
            //     }
            // }
            var url;
            if(this.id){
                url = "/scrm/Task/up"
            }else{
                url = "/scrm/Task/add"
            }
            var path;
            var query = "";
            if(this.detail == "pc"){
                path = "/scrm_pc/client/clientList";
                query = { detail: "pc",client: this.client }
            }else if(this.detail == "wap"){
                path = this.$router.go(-1);
                query = { id: this.client }
            }else{
                if(isMobile()){
                    path = "/scrm_wap/task/taskAllList"
                }else{
                    path = "/scrm_pc/task"
                }
            }
            
            console.log(isMobile())
            requestXml(url,"POST",() => {
                this.$router.push({
                    path: path,
                    query: query
                });
            
            },{ "id": this.id,
                "start":this.start._d.getTime(),
                "end":	this.end._d.getTime(),
                "type": this.type,	
                "mode": this.mode,	
                "staff_uid": this.staff_uid,	
                "content": this.desc,
                "ids": this.client})
        }
    }
};
</script>
<style scoped>
    .content .content_row{
        align-items: start;
        margin: 2rem 0;
    }
    .content .content_row .content_right{width: calc(100% - 15rem);text-align: left;}
    /* .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: #36A3FF;
        border-color: #36A3FF;
    } */
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    /* 输入框 下拉框 */
    .content .content_row input,.content .content_row .ant-select{
        width: 100%;
    }
    .content .content_row .ant-select-selection--multiple input{
        width: 0;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker{
        width: 100% !important;
    }
    .content .content_row .ant-calendar-picker input{
        width: 45% !important;
    }
    .save {
        text-align: left;
    }
    .save .ant-btn{
        width: 100px;
        height: 40px;
        background: #36A3FF;
        border-radius: 4px;
        margin-left: 13rem;
        position: inherit;
    }
    .ant-radio-button-wrapper{
        margin-bottom: 5px;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        background-color: #1890ff;
        color: #fff;
    }
    @media screen and (min-width: 750px){
        .ant-modal {
            width: 600px !important;
        }
        .content .content_row{
            align-items: center;
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .content_right{
            width: calc(100% - 120px);
        }
        .content .content_row .info{
            width: 90px;
            padding-right: 10px;
        }
        /* 日期 */
        .content .content_row .ant-calendar-picker{
            width: 400px !important;
        }
        .content .content_row .ant-calendar-picker input{
            width: 45% !important;
        }
        /* input ,selsct */
        .content .content_row input,.content .content_row .ant-select{
            width: 400px;
        }
        /* textarea */
        .content .content_row textarea.ant-input{width: 60%;}
        .save .ant-btn{margin-left: 90px;}

        .ant-radio-button-wrapper{
            margin-bottom: 0;
        }
    }
</style>
